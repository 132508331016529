<template>
	<v-navigation-drawer
		id="core-navigation-drawer"
		v-model="drawer"
		class="layout-dashboard"
		:expand-on-hover="expandOnHover"
		:right="$vuetify.rtl"
		mobile-breakpoint="960"
		app
		width="300"
		v-bind="$attrs"
	>
    <!-- <v-divider class="mb-1" /> -->

    <template v-slot:prepend>
      <v-expand-x-transition>
        <v-row
            :class="drawer ? 'brand-active-drawer' : 'brand-inactive-drawer'"
        >
          <v-col class="ma-0 pa-0">
            <img src="@/assets/logo_vital_checkups.svg" />
          </v-col>
        </v-row>
      </v-expand-x-transition>
    </template>

    <v-list
        nav
        dense
        :class="!drawer ? '' : 'layout-sider'"
	>
		<v-list-item
			:to="item.path"
			:class="drawerClass(item)"
			v-for="item in items"
			:key="item.title"
			link
		>
		<v-list-item-icon>
			<img :src="item.icon" :class="iconClass(item)" height="28px" />
		</v-list-item-icon>

		<v-list-item-content>
			<span :class="titleClass(item)">{{ item.title }}</span>
		</v-list-item-content>
		</v-list-item>

    </v-list>

    <template v-slot:append>
      <div v-if="roleName === 'HOSPITAL_ADMIN' || roleName === 'DOCTOR' || roleName === 'NURSE'"
           class="d-flex justify-center"
      >
        <v-list-item class="text-center">
          <div class="d-flex flex-column align-center mx-2 mb-1" style="border-radius: 15px; z-index:100">
            <v-card  color="primary" style="min-width:250px" max-width="250px" height="37"  @click="branchSelector">
              <p style="color:white;" class="mx-auto mt-2">{{ userObject.userData?.hospitalAssignedData?.hospitalName }}</p>
            </v-card>
          </div>
        </v-list-item>
      </div>
      <div v-if="roleName === 'ORGANIZATION_ADMIN'" class="d-flex justify-center">
        <v-list-item class="py-1">
          <v-list-item-content class="d-flex align-center elevation-1 text-center" style="border-radius: 15px;">
            <span style="font-weight: bold;text-align: center">{{ userObject.userData?.organizationAssignedData?.institutionName }}</span>
            <span style="text-align: center;font-size: 13px;text-transform: lowercase;">{{ userObject.userData?.organizationAssignedData?.contactEmail }}</span>
            <span>{{ userObject.userData?.organizationAssignedData?.contactPhone }}</span>
          </v-list-item-content>
        </v-list-item>
      </div>
    </template>

	<BranchSelectorDialog
		v-if="dialog"
		:dialog="dialog"
		@false="dialog = false"
		@close="closeDialog"
	/>
  </v-navigation-drawer>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import {
	dashboardBlack,
	dashboardWhite,
	medicalTeamBlack,
	patientsBlack,
	patientsWhite,
	medicalTeamWhite,
	hospitalWhite,
	hospitalBlack,
	signOutBlack,
	organizationsBlack,
	organizationsWhite,
	billWhite,
	billBlack,
	supportBlack,
	supportWhite,
	verifireBlack,
	verifireWhite,
	mentalHealthWhite,
	mentalHealthBlack,
	// mentalHealthBlackAssign,
	// mentalHealthWhiteAssign,
} from '@/assets';

import BranchSelectorDialog from '../dialogs/BranchSelectorDialog.vue';

const { LOGO } = require('@/assets/logo_vital_checkups.svg');

export default {
	name: 'DashboardCoreDrawer',
	components: {
		BranchSelectorDialog,
	},
	props: {
		expandOnHover: {
			type: Boolean,
			default: false,
		},
	},

	data: () => ({
		expand_reports: false,
		theme: process.env.VUE_APP_THEME,
		appVersion: process.env.VUE_APP_VERSION,
		sign_out_black: signOutBlack,
		logo: LOGO,
		emergencyDialog: false,
		webrtcErrorDialog: false,
		onLine: null,
		internetStatus: 'Connected',
		mini: true,
		dialog: false,
	}),
	computed: {
		...mapState({
			userData: (state) => state.authentication.userData,
		}),
		...mapGetters({
			userObject: 'authentication/getUserObject',
			roleName: 'authentication/getRole',
			specialization: 'authentication/getSpecialization',
		}),
		drawer: {
			get () {
				return this.$store.state.drawer;
			},
			set (val) {
				this.$store.commit('SET_DRAWER', val);
			},
		},
		items () {
			switch (this.roleName) {
			case 'SYSTEM_ADMIN':
				return [
					{
						title: this.$t('organizations'),
						icon: this.$route.name.split(' ').join('').toLowerCase() === 'organizations' || this.$route.name === 'Organization' ? organizationsWhite : organizationsBlack,
						path: '/organizations',
					},
					{
						title: this.$t('hospitals'),
						icon: this.$route.name.split(' ').join('').toLowerCase() === 'offices' || this.$route.name === 'Office' ? hospitalWhite : hospitalBlack,
						path: '/offices',
					},
					{
						title: this.$t('admins'),
						icon: this.$route.name.split(' ').join('').toLowerCase() === 'admins' ? patientsWhite : patientsBlack,
						path: '/admins',
					},
					{
						title: this.$t('support'),
						icon: this.$route.name.split(' ').join('').toLowerCase() === 'support' ? supportWhite : supportBlack,
						path: '/support',
					},
					{
						title: this.$t('verifier'),
						icon: this.$route.name.split(' ').join('').toLowerCase() === 'verifier' ? verifireWhite : verifireBlack,
						path: '/verifier',
					},
					{
						title: this.$t('invoices-menu'),
						icon: this.$route.name.split(' ').join('').toLowerCase() === 'invoices' ? verifireWhite : verifireBlack,
						path: '/invoices',
					},
				];
			case 'ORGANIZATION_ADMIN':
				return [
					{
						title: this.$t('organizations'),
						icon: this.$route.name.split(' ').join('').toLowerCase() === 'organizations' || this.$route.name === 'Organization' ? organizationsWhite : organizationsBlack,
						path: '/organizations',
					},
					{
						title: this.$t('hospitals'),
						icon: this.$route.name.split(' ').join('').toLowerCase() === 'offices' || this.$route.name === 'Office' ? hospitalWhite : hospitalBlack,
						path: '/offices',
					},
					{
						title: this.$t('admins'),
						icon: this.$route.name.split(' ').join('').toLowerCase() === 'admins' ? patientsWhite : patientsBlack,
						path: '/admins',
					},
					{
						title: this.$t('staff'),
						icon: this.$route.name.split(' ').join('').toLowerCase() === 'medicalstaff' ? medicalTeamWhite : medicalTeamBlack,
						path: '/medicalstaff',
					},
					// {
					// 	title: this.$t('patients'),
					// 	icon: this.$route.name.split(' ').join('').toLowerCase() === 'patients' || this.$route.name === 'Patient Details' ? patientsWhite : patientsBlack,
					// 	path: '/patients',
					// },
					// {
					// 	title: this.$t('medicalTeams'),
					// 	icon: this.$route.name.split(' ').join('').toLowerCase() === 'medicalteam' ? medicalTeamWhite : medicalTeamBlack,
					// 	path: '/medicalteam',
					// },
					{
						title: this.$t('billings'),
						icon: this.$route.name.split(' ').join('').toLowerCase() === 'billings' ? billWhite : billBlack,
						path: '/billings',
					},
				];
			case 'HOSPITAL_ADMIN':
				return [
					{
						title: this.$t('staff'),
						icon: this.$route.name.split(' ').join('').toLowerCase() === 'medicalstaff' ? medicalTeamWhite : medicalTeamBlack,
						path: '/medicalstaff',
					},
					{
						title: this.$t('patients'),
						icon: this.$route.name.split(' ').join('').toLowerCase() === 'patients' || this.$route.name === 'Patient Details' ? patientsWhite : patientsBlack,
						path: '/patients',
					},
					{
						title: this.$t('medicalTeams'),
						icon: this.$route.name.split(' ').join('').toLowerCase() === 'medicalteam' ? medicalTeamWhite : medicalTeamBlack,
						path: '/medicalteam',
					},
					{
						title: this.$t('billings'),
						icon: this.$route.name.split(' ').join('').toLowerCase() === 'billings' ? billWhite : billBlack,
						path: '/billings',
					},
				];
			default:
				// if (this.specialization?.doctorMetadataReadDto?.spetializationData.some(item => item.spetializationId === 17)) {
				return [
					{
						title: this.$t('dashboardIconTooltip'),
						icon: this.$route.name.split(' ').join('').toLowerCase() === 'dashboard' ? dashboardWhite : dashboardBlack,
						path: '/dashboard',
					},
					{
						title: this.$t('staff'),
						icon: this.$route.name.split(' ').join('').toLowerCase() === 'medicalstaff' ? medicalTeamWhite : medicalTeamBlack,
						path: '/medicalstaff',
					},
					{
						title: this.$t('patients'),
						icon: this.$route.name.split(' ').join('').toLowerCase() === 'patients' || this.$route.name === 'Patient Details' ? patientsWhite : patientsBlack,
						path: '/patients',
					},
					{
						title: this.$t('medicalTeam'),
						icon: this.$route.name.split(' ').join('').toLowerCase() === 'medicalteam' ? medicalTeamWhite : medicalTeamBlack,
						path: '/medicalteam',
					},
					{
						title: this.$t('mental-health-template'),
						icon: this.$route.name.split(' ').join('').toLowerCase() === 'mentalhealthtemplate' || this.$route.name === 'Assessments' ? mentalHealthWhite : mentalHealthBlack,
						path: '/mentalhealthtemplate',
					},
					// {
					// 	title: this.$t('mental-health-assignments'),
					// 	icon: this.$route.name.split(' ').join('').toLowerCase() === 'mentalhealthpatient' || this.$route.name === 'Wellness Patient' ? mentalHealthWhiteAssign : mentalHealthBlackAssign,
					// 	path: '/mentalhealthpatient',
					// },
					{
						title: this.$t('billings'),
						icon: this.$route.name.split(' ').join('').toLowerCase() === 'billings' ? billWhite : billBlack,
						path: '/billings',
					},

				];
          // }
          // else {
          // 	return [
          // 		{
          // 			title: this.$t('dashboardIconTooltip'),
          // 			icon: this.$route.name.split(' ').join('').toLowerCase() === 'dashboard' ? dashboardWhite : dashboardBlack,
          // 			path: '/dashboard',
          // 		},
          // 		{
          // 			title: this.$t('medicalTeam'),
          // 			icon: this.$route.name.split(' ').join('').toLowerCase() === 'medicalteam' ? medicalTeamWhite : medicalTeamBlack,
          // 			path: '/medicalteam',
          // 		},
          // 		{
          // 			title: this.$t('patients'),
          // 			icon: this.$route.name.split(' ').join('').toLowerCase() === 'patients' || this.$route.name === 'Patient Details' ? patientsWhite : patientsBlack,
          // 			path: '/patients',
          // 		},
          // 	];
          // }
			}
		},
	},
	mounted () {
	},
	methods: {
		logout () {
			this.$router.push({ name: 'Login' });
		},
		drawerClass (item) {
			if (item.path === '/dashboard') {
				return item.path.substring(1) === this.$route.name.toLowerCase() ? 'layout-sider-item-active-dashboard' : 'layout-sider-item-deactive';
			} else {
				if (this.$route.name === 'Patient Details') {
					return item.path.substring(1) === 'patients' ? 'layout-sider-item-active-dashboard' : 'layout-sider-item-deactive';
				}
				return item.path.substring(1) === this.$route.name.split(' ').join('').toLowerCase() ? 'layout-sider-item-active' : 'layout-sider-item-deactive';
			}
		},
		iconClass (item) {
			if (this.$route.name === 'Patient Details') {
				return item.path.substring(1) === 'patients' ? 'icon-active' : 'icon-deactive';
			}
			if (this.$route.name === 'Assessments') {
				return item.path.substring(1) === 'mentalhealthtemplate' ? 'icon-active' : 'icon-deactive';
			}
			// if (this.$route.name === 'Wellness Patient') {
			// 	return item.path.substring(1) === 'mentalhealthpatient' ? 'icon-active' : 'icon-deactive';
			// }
			if (this.$route.name === 'Organization') {
				return item.path.substring(1) === 'organizations' ? 'icon-active' : 'icon-deactive';
			}
			if (this.$route.name === 'Office') {
				return item.path.substring(1) === 'offices' ? 'icon-active' : 'icon-deactive';
			}
			const iconClass = item.path.substring(1) === this.$route.name.split(' ').join('').toLowerCase() ? 'icon-active' : 'icon-deactive';
			return iconClass;
		},
		titleClass (item) {
			if (this.$route.name === 'Patient Details') {
				return item.path.substring(1) === 'patients' ? 'primary--text' : 'main_black--text';
			}
			return item.path.substring(1) === this.$route.name.split(' ').join('').toLowerCase() ? 'primary--text' : 'main_black--text';
		},
		branchSelector () {
			// this.dialog = true;
			if (this.roleName === 'DOCTOR' || this.roleName === 'NURSE') {
				this.dialog = true;
			} else {
				this.dialog = false;
			}
		},
		closeDialog () {
			this.dialog = false;
		},
	},
};
</script>
