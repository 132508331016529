<template>
    <v-dialog
        v-model="dialog"
        width="700"
        :fullscreen="$vuetify.breakpoint.xsOnly"
        persistent
    >
      <v-card style="overflow:auto; height:300px;">
        <v-card-title class="secondary py-1" style="height: 40px; position:sticky;top:0;">
            <span class="dialog-title" style="font-size: 18px">{{$t('branch-selector')}}</span>
            <v-spacer />
            <v-icon small color="primary" @click="$emit('false')">mdi-window-close</v-icon>
        </v-card-title>
        <div>
                    <v-simple-table dense style="height:250px">
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th class="text-left">
                                        {{$t('name')}}
                                    </th>
                                    <th></th>
                                  <th></th>
                                </tr>
                            </thead>
                            <tbody style="overflow-y:auto">
                                <tr
                                    v-for="item in userData.hospitalsAssignedData"
                                    :key="item.id"
                                    @click="selectedBranch(item)"
                                    >

                                    <td class="text-left">{{ item.hospitalName }}</td>
                                    <td></td>
                                      <td v-if="item.id === userData.hospitalAssignedData.id">
                                        <span class="mdi mdi-checkbox-marked float-right" style="font-size:22px; color:#6600FF"></span>
                                      </td>
                                    <td v-else>
                                      <span class="mdi mdi-checkbox-blank-outline float-right" style="font-size:22px; color:#6600FF "></span>
                                    </td>
                                </tr>
                            </tbody>
                        </template>
                      </v-simple-table>
          </div>
      </v-card>
    </v-dialog>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

export default {
	props: ['dialog', 'specializations'],
	components: {
		// BirthdatePicker,
	},
	data () {
		return {
			loader: null,
			loading: false,
			valid: false,
			title: null,
		};
	},
	computed: {
		...mapState({
			userData: (state) => state.authentication.userData,
		}),
		...mapGetters({
		}),
	},
	mounted () {
		// this.scaleAndTempUnit = this.userSettings.Units;
		// this.glucoseAndCholesterolUnit = this.userSettings.Concentration;
		// this.addressData();
		// this.language = this.$cookies.get('language');
	},
	methods: {
		selectedBranch (item) {
			this.$store.commit('authentication/SET_SELECTED_OFFICE', item);
			window.location.reload(true);
			this.$emit('false');
		},
	},
};
</script>
